import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
// import 'swiper/components/effect-fade/effect-fade.min.css';

const slideCmn = document.querySelectorAll('.j-sectionscroll_cmn');

const slideCmnAction = () => {
    [...slideCmn].forEach(cmn => {
        cmn.classList.toggle('swiper-moving');
    });
}

const slideCmnActionNext = () => {
    [...slideCmn].forEach(cmn => {
        cmn.classList.remove('swiper-active-next', 'swiper-active-prev');
    });
    let slideActive = document.querySelector('.swiper-slide-active');
    slideActive.classList.toggle('swiper-active-next');
}

const slideCmnActionPrev = () => {
    [...slideCmn].forEach(cmn => {
        cmn.classList.remove('swiper-active-next', 'swiper-active-prev');
    });
    let slideActive = document.querySelector('.swiper-slide-active');
    slideActive.classList.toggle('swiper-active-prev');
}



window.onload = () => {

    const swiper = new Swiper(".j-sectionscroll", {
        speed: 1600,
        preventInteractionOnTransition: true,
        passiveListeners: true,
        followFinger: false,
        mousewheel: true,
        resistance: false,
        direction: "vertical",
        // longSwipes: false,
        // allowTouchMove: false,
        // preventIntercationOnTransition: true,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },

        // on: {
        //     slideChangeTransitionStart: () => {
        //         slideCmnAction();
        //     },
        //     slideChangeTransitionEnd: () => {
        //         slideCmnAction();
        //     },
        //     slideNextTransitionStart: () => {
        //         slideCmnActionNext();
        //     },
        //     slidePrevTransitionStart: () => {
        //         slideCmnActionPrev();
        //     },
        // },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        // breakpoints: {
        //     920: {
        //         allowTouchMove: true,
        //         longSwipes: true,
        //         preventIntercationOnTransition: false,
        //     }
        // },
    });

    const loader = document.querySelector('.loading');
    const mv = document.querySelector('.j-mv');
    loader.classList.remove('loading');
    mv.classList.remove('is-active');
};